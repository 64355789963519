import { type FC } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ProvideAuth } from '@lib/hooks'
import { ScrollToTop } from '@lib/components'

import { CampaignCreate, CharityList, CharityMatchPots, CreateMatchPot, Home, MatchPotCharityList } from '~/pages'

import { Header } from '~/components'
import { browserExceptions } from '@lib/services'
import { ActiveCampaign, Products } from '@shamaazi/mytennights'
import { CharityStripeAccounts } from '~/pages/CharityStripeAccounts'
import { AuthenticatedRoute } from '~/components/AuthenticatedRoute'

void browserExceptions.initialize()

const queryClient = new QueryClient()

const App: FC = () => {
  return <ProvideAuth>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Header />
        <ScrollToTop />
        <Switch>
          <AuthenticatedRoute exact path="/">
            <Home />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/accounts">
            <CharityStripeAccounts />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/match-pots/:charityId/create">
            <CreateMatchPot />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/match-pots">
            <MatchPotCharityList />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/match-pots/:charityID">
            <CharityMatchPots />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/mytennights">
            <CharityList product={Products.myTenNights} currentCampaign={ActiveCampaign.myTenNights} />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/mytennights/:charityID">
            <CampaignCreate product={Products.myTenNights} currentCampaign={ActiveCampaign.myTenNights} />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/mytendays">
            <CharityList product={Products.myTenDays} currentCampaign={ActiveCampaign.myTenDays} />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/mytendays/:charityID">
            <CampaignCreate product={Products.myTenDays} currentCampaign={ActiveCampaign.myTenDays} />
          </AuthenticatedRoute>
          <Route>
            <main>
              <div className="px-4 mt-4">
                <h1 className="font-mono text-lg font-bold text-sz-gray-700">This page could not be found</h1>
              </div>
            </main>
          </Route>
        </Switch>
      </Router>
    </QueryClientProvider>
  </ProvideAuth>
}

export default App
