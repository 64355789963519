import { withAuth } from '@lib/hooks'
import { type Country } from '@lib/services'
import { ActiveCampaign } from '@shamaazi/mytennights'

export interface MyTenNightsCause {
  cause_id: string
  title: string
  description: string
  category: string
  active: boolean
  position: number
}

interface APIMyTenNightsCampaign {
  CharityID: string
  CharityName: string
  Causes: Record<string, MyTenNightsCause>
  Country: Country
  Tier?: string
}

export interface MyTenNightsCampaign {
  charityID: string
  charityName: string
  causes: MyTenNightsCause[]
  country: Country
  tier?: string
}

export const getMyTenNightsCampaign = async (charityID: string): Promise<MyTenNightsCampaign | null> => getMyTenXCampaign(charityID, ActiveCampaign.myTenNights)
export const getMyTenDaysCampaign = async (charityID: string): Promise<MyTenNightsCampaign | null> => getMyTenXCampaign(charityID, ActiveCampaign.myTenDays)
export const getMyTenXCampaign = withAuth(async (charityID: string, campaign: ActiveCampaign): Promise<MyTenNightsCampaign | null> => {
  const res = await fetch(`/api/charity/${charityID}/mytennights_campaign/${campaign}`)

  // 400 means the charity does not currently have a MyTenNights campaign
  if (res.status === 400) {
    return null
  }

  if (res.status !== 200) {
    throw new Error(`Unexpected status ${res.status}`)
  }

  const c: APIMyTenNightsCampaign = await res.json()

  return {
    charityID: c.CharityID,
    charityName: c.CharityName,
    causes: c.Causes ? Object.values(c.Causes) : [],
    country: c.Country,
    tier: c.Tier
  }
})

export const myTenNightsCampaignCreate = withAuth(async (charityID: string, tier: string): Promise<string> => {
  const res = await fetch(`/api/charity/${charityID}/mytennights_campaign/${tier}`, {
    method: 'POST'
  })

  if (res.status !== 200) {
    throw new Error(`Unexpected status ${res.status}`)
  }

  return await res.json()
})

export const UpdateCampaignTier = withAuth(async (charityID: string, tier: string): Promise<string> => {
  const res = await fetch(`/api/charity/${charityID}/mytennights_campaign/update`, {
    method: 'PUT',
    body: JSON.stringify({ tier })
  })

  if (res.status !== 200) {
    throw new Error(`Unexpected status ${res.status}`)
  }

  return await res.json()
})

export const myTenNightsCampaignList = async (): Promise<MyTenNightsCampaign[]> => myTenXCampaignList(ActiveCampaign.myTenNights)
export const myTenDaysCampaignList = async (): Promise<MyTenNightsCampaign[]> => myTenXCampaignList(ActiveCampaign.myTenDays)
const myTenXCampaignList = withAuth(async (campaign: ActiveCampaign): Promise<MyTenNightsCampaign[]> => {
  const res = await fetch(`api/charity/mytennights/${campaign}/list`)

  if (res.status !== 200) {
    throw new Error(`Unexpected status ${res.status}`)
  }

  const campaigns = await res.json()

  return campaigns.map((c: APIMyTenNightsCampaign) => ({
    charityID: c.CharityID,
    charityName: c.CharityName,
    causes: Object.values(c.Causes),
    country: c.Country
  }))
})
