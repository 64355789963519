import { Route } from 'react-router-dom'
import { useAuth } from '@lib/hooks'
import { Login } from '~/pages'

export const AuthenticatedRoute = ({ children, ...routeArgs }) => {
  const { user } = useAuth()
  return <Route {...routeArgs}>
    {user ? children : <Login />}
  </Route>
}
