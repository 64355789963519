import { type Currency } from '@lib/services'
import { withAuth } from '@lib/hooks'

export interface MatchPot {
  matchPotID: string
  name: string
  currency: Currency
  currentAmount: number
  totalAmount: number
}

interface APIMatchPot {
  match_pot_id: string
  name: string
  currency: Currency
  current_amount: number
  total_amount: number
}

export const listMatchPots = withAuth(async (charityID: string): Promise<MatchPot[]> => {
  const res = await fetch(`/api/charity/${charityID}/match_pot`)

  if (res.status !== 200) {
    throw new Error(`Unexpected status ${res.status}`)
  }

  const pots: APIMatchPot[] = await res.json() ?? []

  return pots.map(p => {
    return {
      matchPotID: p.match_pot_id,
      name: p.name,
      currentAmount: p.current_amount,
      totalAmount: p.total_amount,
      currency: p.currency
    }
  })
})

export interface CreateMatchPotInputs {
  name: string
  amount: number
}

export const createCharityMatchPot = withAuth(async (charityId: string, data: CreateMatchPotInputs): Promise<void> => {
  data.amount = data.amount * 100

  const res = await fetch(`/api/charity/${charityId}/match_pot`, {
    method: 'POST',
    body: JSON.stringify(data)
  })

  if (res.status !== 200) {
    throw new Error(`Unexpected status ${res.status}`)
  }

  return await res.json()
})
