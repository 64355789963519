import { Button } from '@lib/components'
import { useAsync } from '@lib/legacycomponents'
import { ComponentsTestId } from '@lib/testing'

const CharityCard = ({ charity, onApprove, onReject }) => {
  const { execute: approve, loading: approveLoading, error: approveError } = useAsync(onApprove, false)
  const { execute: reject, loading: rejectLoading, error: rejectError } = useAsync(onReject, false)
  const hasError = approveError || rejectError
  const dataTestId = ComponentsTestId.charityApproveButton + charity.name

  const reps = charity.representatives.map((r, i) => <div key={i}>
    <p className="my-2 text-base">
      Name: {r.name}<br />
      Position: {r.position}<br />
      Email: {r.email}<br />
      Phone: {r.phone_number}
    </p>
  </div>)

  return <div className="flex flex-col p-6 my-4 bg-white shadow-lg rounded-xl">
    <h2 className="font-mono text-lg font-bold text-sz-gray-700">{charity.name}</h2>
    <div className="flex flex-wrap items-end justify-between">
      <div className="flex flex-col">
        <p className="my-2">
          Country: {charity.country}<br />
          Registration Number: {charity.registration_number}
        </p>
        <hr />
        {reps}
      </div>
      <div className="flex flex-col">
        {hasError ? <p className="mt-4 text-sm text-sz-pink">Oh no! Something went wrong</p> : <></>}
        <div className="flex">
          <Button
            className="mr-4"
            variant="sz-outline"
            loading={rejectLoading}
            disabled={approveLoading || rejectLoading}
            onClick={() => reject(charity)}>
            Reject
          </Button>

          <Button
            variant="sz-primary"
            loading={approveLoading}
            disabled={approveLoading || rejectLoading}
            data-test-id={dataTestId}
            onClick={() => approve(charity)}>
            Approve
          </Button>
        </div>
      </div>
    </div>
  </div>
}

export const PendingCharitiesList = ({ charities, onApprove, onReject }) => {
  const charityCards = charities.map((c) => <CharityCard onApprove={onApprove} onReject={onReject} charity={c} key={c.charity_id} />)

  if (charities.length === 0) {
    return <p className="my-4">There are no charities to approve</p>
  }

  return <div className="flex flex-col my-4">
    {charityCards}
  </div>
}
