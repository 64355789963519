import { type FC, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useAsync } from '@lib/legacycomponents'
import { Button, Page, SelectWithRef } from '@lib/components'
import { type ActiveCampaign, CampaignSetup, type Products } from '@shamaazi/mytennights'
import { getMyTenXCampaign, myTenNightsCampaignCreate } from '~/service/mytennights'

export interface CampaignCreateProps {
  readonly product: Products
  readonly currentCampaign: ActiveCampaign
}

export const CampaignCreate: FC<CampaignCreateProps> = ({ product, currentCampaign }) => {
  const { charityID } = useParams<{ charityID: string }>()
  const { register, handleSubmit, formState: { errors } } = useForm()
  const history = useHistory()

  const { result: campaign, loading, error } = useAsync(useCallback(async () => await getMyTenXCampaign(charityID, currentCampaign), [charityID, currentCampaign]))

  const submit = async (data: { tier: string }): Promise<void> => {
    await myTenNightsCampaignCreate(charityID, data.tier)
    history.push(`/${product}`)
  }

  const { execute: onSubmit, loading: submitting, error: submitError } = useAsync(useCallback(submit, [charityID, history, product]), false)

  return <main>
    <div className="p-4">
      <Link className="underline" to={`/${product}`}>&lt; Back to charity list</Link>
    </div>

    <Page skinny className="px-4">
      <h1 className="font-mono text-lg font-bold text-sz-gray-700">{charityID}</h1>

      {loading && <p className="mt-4 font-mono text-sz-gray-700">Loading...</p>}
      {error !== null && <p className="mt-4 text-sz-pink">Oops! We were unable to load the campaign information.</p>}
      {campaign !== null &&
        <p className="mt-4"><strong>{`This charity already has a ${campaign?.tier ?? CampaignSetup.currentCampaignName} campaign`}</strong></p>}

      {!loading && error === null && campaign === null && <>
        <p className="mt-4">This charity does not currently have a campaign for {CampaignSetup.currentCampaignName}.</p>
        <p className="mt-2">Please check this charity has paid the onboarding fees, then select the tier to create their campaign.</p>
        <p className="mt-2">Note that you can&apos;t change a tier once it is set - contact the devs if you need one changing! :)</p>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
          <label>
            Tier:
            <SelectWithRef
              variant="sz-inline"
              className="mt-4 ml-4"
              defaultValue=""
              {...register('tier', { required: true, validate: { validTier: (value) => value === 'widget' || value === 'homepage' } })}>
              <option value="" disabled={true}>Choose a tier</option>
              <option value="widget">Widget</option>
              <option value="homepage">Home page</option>
            </SelectWithRef>
          </label>
          {errors.tier as boolean && <p className="mt-4 text-sm text-right text-sz-pink">We were unable to accept the selected value</p>}

          <div className="mt-8">
            <Button variant="sz-primary" loading={loading || submitting} disabled={loading || submitting}>
              CREATE CAMPAIGN
            </Button>
          </div>
          {submitError !== null && <p className="text-sm text-right text-sz-pink">We were unable to create the campaign.</p>}
        </form>
      </>}
    </Page>
  </main>
}
