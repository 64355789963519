import { type FC } from 'react'
import { LoginForm } from '@lib/components'

export const Login: FC = () => {
  return <main>
    <div className="flex flex-col max-w-3xl px-4 mx-auto">
      <h1 className="mt-8 font-mono text-lg font-bold text-sz-gray-700">Admin login</h1>
      <LoginForm fontVariant="sz" buttonVariant="sz-primary" />
    </div>
  </main>
}
